import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  smoothScroll('a[href*="#"]', { offset: 130 })

  $('.read-more').on('click', function () {
    $(this).prev().toggle()
    if ($(this).text() == 'Mehr ansehen') {
      $(this).text('Weniger ansehen')
    } else {
      $(this).text('Mehr ansehen')
    }
  })

  $(document).scroll(function () {
    var scroll = $(this).scrollTop()
    if (scroll > 0) {
      $('.header').addClass('fixed')
    } else {
      $('.header').removeClass('fixed')
    };
  })

  $('.header-nav-list li a').click(function () {
    $('.header-nav-content').collapse('hide')
  })
})

$(window).scroll(function () {
  const position = window.pageYOffset
  $('.grid-content').each(function () {
    const target = $(this).offset().top
    const id = $(this).attr('id')
    const navLinksHome = $('.header-nav-list li a')
    if (position > target - 200 && id) {
      navLinksHome.removeClass('current')
      $('.header-nav-list li a[href*="#' + id + '"]').addClass('current')
    } else if ($(window).scrollTop() === 0) {
      navLinksHome.removeClass('current')
    } else {
      $('.header-nav-list li a current').removeClass('current')
    }
  })
})

$(window).scroll(function () {
  if ($(this).scrollTop() >= 400) {
    $('.allergene-wrapper').fadeIn(200).addClass('visible')
  } else {
    $('.allergene-wrapper').fadeOut(200).removeClass('visible')
  }
})

$('.carousel.gallery-slider .neos-contentcollection').slick({
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1400,
  cssEase: 'linear',
  ease: 'ease-in',
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  centerMode: false,
  useTransform: true,
  variableWidth: true,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        centerMode: true
      }
    },
    {
      breakpoint: 500,
      settings: {
        variableWidth: false
      }
    }
  ]
})

$('.carousel.customer-comments--slider .neos-contentcollection').slick({
  autoplay: false,
  infinite: true,
  autoplaySpeed: 0,
  arrows: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        touchMove: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
      }
    }
  ]
})
